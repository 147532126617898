.prompt {
  background-color: rgba(0, 0, 0, .7);
  bottom: 0;
  color: color("grey", "lighten-5");
  left: 0;
  padding: 1rem;
  position: fixed;
  right: 0;
  text-align: center;

  button[type="submit"] {
    margin-right: 5px;
  }

  input {
    color: color("grey", "lighten-2");
  }
}