/* Materialize variables */

// 1. Colors
$primary-color: color("light-blue", "darken-4");

// 4. Cards
$card-padding: 1rem;

// 10. Forms
$input-height: 2rem;
$input-border-color: color("grey", "lighten-1");
