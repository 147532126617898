.card {
  .card-content {
    padding: ($card-padding / 1.5) $card-padding;

    .card-title {
      font-size: 1.4rem;
      margin-bottom: .2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .card-labels {
    height: 56px; // 2 rows
    overflow: hidden;

    & > .chip {
      display: inline-block;
    }
  }

  .card-action {
    padding: {
      top: .2rem;
      bottom: .2rem;
    }
  }
}